@tailwind base;
@tailwind components;
@tailwind utilities;

.menu::-webkit-scrollbar {
  width: 12px;
  background-color: #eff1f5;
}

.menu::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: transparent;
}

.menu::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #515769;
  border: 2px solid #eff1f5;
}
