*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.right-1 {
  right: .25rem;
}

.-left-1 {
  left: -.25rem;
}

.top-\[2\%\] {
  top: 2%;
}

.bottom-\[-10\%\] {
  bottom: -10%;
}

.bottom-\[-18\%\] {
  bottom: -18%;
}

.z-20 {
  z-index: 20;
}

.z-10 {
  z-index: 10;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.m-1 {
  margin: .25rem;
}

.m-8 {
  margin: 2rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.-mx-1\.5 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-\[-2rem\] {
  margin-left: -2rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.h-24 {
  height: 6rem;
}

.h-screen {
  height: 100vh;
}

.h-\[5rem\] {
  height: 5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-6 {
  height: 1.5rem;
}

.h-4 {
  height: 1rem;
}

.h-2 {
  height: .5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-8 {
  height: 2rem;
}

.h-\[76vh\] {
  height: 76vh;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-96 {
  height: 24rem;
}

.h-56 {
  height: 14rem;
}

.h-52 {
  height: 13rem;
}

.h-full {
  height: 100%;
}

.h-60 {
  height: 15rem;
}

.h-\[30vh\] {
  height: 30vh;
}

.h-\[10vh\] {
  height: 10vh;
}

.h-\[60vh\] {
  height: 60vh;
}

.max-h-\[60vh\] {
  max-height: 60vh;
}

.max-h-\[87vh\] {
  max-height: 87vh;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-\[60vh\] {
  min-height: 60vh;
}

.min-h-\[68vh\] {
  min-height: 68vh;
}

.min-h-\[80vh\] {
  min-height: 80vh;
}

.min-h-\[90vh\] {
  min-height: 90vh;
}

.min-h-\[87vh\] {
  min-height: 87vh;
}

.min-h-\[100vh\] {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.w-24 {
  width: 6rem;
}

.w-auto {
  width: auto;
}

.w-72 {
  width: 18rem;
}

.w-\[5rem\] {
  width: 5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-6 {
  width: 1.5rem;
}

.w-4 {
  width: 1rem;
}

.w-2 {
  width: .5rem;
}

.w-\[80vh\] {
  width: 80vh;
}

.w-5 {
  width: 1.25rem;
}

.w-8 {
  width: 2rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-96 {
  width: 24rem;
}

.w-56 {
  width: 14rem;
}

.w-\[44\%\] {
  width: 44%;
}

.w-\[5vw\] {
  width: 5vw;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-\[18vw\] {
  width: 18vw;
}

.w-\[40\%\] {
  width: 40%;
}

.flex-\[100\%\] {
  flex: 100%;
}

.flex-\[60\%\] {
  flex: 60%;
}

.flex-\[40\%\] {
  flex: 40%;
}

.flex-1 {
  flex: 1;
}

.flex-\[70\%\] {
  flex: 70%;
}

.flex-\[30\%\] {
  flex: 30%;
}

.flex-\[1\.5\] {
  flex: 1.5;
}

.flex-\[0\.1\] {
  flex: .1;
}

.flex-\[0\.8\] {
  flex: .8;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-3 {
  gap: .75rem;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.border {
  border-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.bg-\[\#FAFFD1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 255 209 / var(--tw-bg-opacity));
}

.bg-\[\#C8FFD0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(200 255 208 / var(--tw-bg-opacity));
}

.bg-\[\#A1FFCE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(161 255 206 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#A1FFCE\] {
  --tw-gradient-from: #a1ffce;
  --tw-gradient-to: #a1ffce00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#FAFFD1\] {
  --tw-gradient-to: #faffd1;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-center {
  object-position: center;
}

.p-2 {
  padding: .5rem;
}

.p-1 {
  padding: .25rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-4 {
  padding: 1rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-3 {
  padding: .75rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-\[0\.6\] {
  padding-top: .6px;
  padding-bottom: .6px;
}

.px-\[0\.3rem\] {
  padding-left: .3rem;
  padding-right: .3rem;
}

.py-\[0\.1rem\] {
  padding-top: .1rem;
  padding-bottom: .1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pt-72 {
  padding-top: 18rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pr-\[2rem\] {
  padding-right: 2rem;
}

.pl-2 {
  padding-left: .5rem;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.font-logo {
  font-family: Inspiration, cursive;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.accent-green-500 {
  accent-color: #22c55e;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.menu::-webkit-scrollbar {
  width: 12px;
  background-color: #eff1f5;
}

.menu::-webkit-scrollbar-track {
  background-color: #0000;
  border-radius: 3px;
}

.menu::-webkit-scrollbar-thumb {
  background-color: #515769;
  border: 2px solid #eff1f5;
  border-radius: 5px;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-2xl:hover {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-green-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

@media (prefers-color-scheme: dark) {
  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }

  .dark\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
  }

  .dark\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
  }

  .dark\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }
}

/*# sourceMappingURL=index.84deba4c.css.map */
